import { useEffect } from 'react';
import { WindowLocation } from '@reach/router';

import { CoverType, MixpanelEvents, ProductName } from '@entities/enums';
import { useCustomMixpanel } from '@services/Mixpanel';
import { PlanDetail } from '@src/modules';
import { Quote } from '@entities/types';
import { DataLayer } from '@services/DataLayerService';

interface Props {
  location: WindowLocation;
  quote?: Quote;
}

export const AbsPage = ({ location, quote }: Props) => {
  const mixpanel = useCustomMixpanel();

  useEffect(() => {
    DataLayer.push([
      {
        event: 'selectedProductName',
        ProductName: ProductName.ABS,
      },
      {
        event: 'selectedCoverType',
        CoverType: CoverType.ABS,
      },
    ]);
  }, []);

  useEffect(() => {
    mixpanel.track(MixpanelEvents.PAGE_PLAN_DETAIL, {
      plan: quote?.coverType,
      pageName: null,
    });
  }, [quote?.coverType, mixpanel]);

  return <PlanDetail quote={quote} location={location} />;
};
